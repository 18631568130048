<!--
 * @Author: jiang
 * @Date: 2021-06-12 11:39:09
 * @Description: 目录分类
-->
<template>
  <div>
    <c-tree
      ref="tree"
      :data="treeData"
      :current="currentKey"
      :submit-loading="submitLoading"
      title="分类信息"
      create-text="新增任务分类"
      :form-option="option"
      @change="onChange"
      @add="onAdd"
      @edit="onEdit"
      @delete="onDelete"
    ></c-tree>
  </div>
</template>

<script>
import CTree from '@/components/tree'
import {
  getTaskCatelogList,
  createTaskCatelog,
  updateTaskCatelog,
  deleteTaskCatelog,
} from '@/api/task'
import { listToTree } from '@/utils/tree'

export default {
  components: {
    CTree,
  },
  props: {
    currentKey: [Number, String],
  },
  data() {
    return {
      treeData: [],
      submitLoading: false,
      option: {
        columns: [
          {
            label: '名称',
            prop: 'name',
            inputAttrs: {
              placeholder: '输入名称',
            },
            rules: [{ required: true, message: '必须填写字段', trigger: 'blur' }],
          },
          {
            label: '备注',
            prop: 'minutes',
            inputAttrs: {
              type: 'textarea',
              placeholder: '输入备注信息',
            },
          },
        ],
      },
    }
  },
  created() {
    this.fetchCategory()
  },
  methods: {
    getName(id) {
      return this.$refs.tree.getName(id)
    },
    fetchCategory() {
      getTaskCatelogList().then(res => {
        this.treeData = listToTree(res, { id: 'id', parentId: 'parent_id', children: 'children' })
      })
    },
    onChange(data) {
      this.$emit('change', data.id)
    },
    onAdd(data) {
      this.submitLoading = true
      const params = {
        name: data.data.name,
        minutes: data.data.minutes,
        parent_id: data.tree.id,
      }
      createTaskCatelog(params)
        .then(res => {
       //   debugger
          this.fetchCategory()
          this.$message.success('添加成功')
        })
        .finally(() => {
          this.submitLoading = false
          this.$refs.tree.closeDialog()
        })
    },
    onEdit(data) {
      this.submitLoading = true
      const params = {
        name: data.data.name,
        minutes: data.data.minutes,
        parent_id: data.tree.parent_id,
      }
      updateTaskCatelog(data.tree.id, params)
        .then(res => {
          this.$message.success('修改成功')
          this.fetchCategory()
        })
        .finally(() => {
          this.submitLoading = false
          this.$refs.tree.closeDialog()
        })
    },
    onDelete(data) {
      this.$confirm('确定要删除当前项吗', '提示', { type: 'warning' })
        .then(() => {
          return deleteTaskCatelog(data.id)
        })
        .then(() => {
          this.$message.success('删除成功')
          this.fetchCategory()
        })
        .catch(() => {})
    },
  },
}
</script>
